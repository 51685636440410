import React from 'react'
import _ from 'lodash'
import Layout from '../components/Layout'
import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/as_constitucion.jpg'
import { ContractButtonSmall } from '../components/Solutions/SolutionDetail.styled'
import FooterContact from './../components/FooterContact'

const AsConstitucion = props => (
  <Layout
    title="Servicio de AS Constitución en México"
    description="Con nuestro servicio “AS Constitución” nos encargamos de constituir tu sociedad en México ante un notario público, y te asesoramos en todo el proceso."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.as_constitucion.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedMessage id="solutions.as_constitucion.content.p1" />
        </p>
        <h2>
          <FormattedMessage id="solutions.as_constitucion.content.subtitle1" />
        </h2>
        <ul>
          {_.map(_.times(8, String), (value, key) => (
            <li key={key}>
              <FormattedHTMLMessage
                id={`solutions.as_constitucion.content.list1.item${value}`}
              />
            </li>
          ))}
        </ul>
        <h2>
          <FormattedMessage id="solutions.as_constitucion.content.subtitle2" />
        </h2>
        <ul>
          {_.map(_.times(4, String), (value, key) => (
            <li key={key}>
              <FormattedHTMLMessage
                id={`solutions.as_constitucion.content.list2.item${value}`}
              />
            </li>
          ))}
        </ul>
        <p style={{ textAlign: 'center' }}>
          <FormattedMessage id="solutions.as_constitucion.content.cost" />
        </p>
        <p>
          <FormattedMessage id="solutions.as_constitucion.content.terms" />
        </p>
      </div>
      <ContractButtonSmall to="/contacto/" small>
        <FormattedMessage id="element.button_hire" />
      </ContractButtonSmall>
    </Section>
    <FooterContact />
  </Layout>
)

export default AsConstitucion
